<template>
  <div class="PopupUserTrade">
    <div class="container__close" @click="childClick()"></div>
    <div class="container__info">
      <div class="wrap__close" @click="childClick()">
        <img src="@/assets/images/public/ic_home_shutdown.webp" alt="" />
      </div>
      <div class="flex-sb-c mb_4px">
        <div class="wrap__info">
          <div class="box__img">
            <img :src="getAvatar(userData['traderAvatar'])" alt="" />
          </div>
          <div class="flex-fs-c-w">
            <div class="box__name">
              {{ userData["traderName"] }}
            </div>
            <div v-if="isTraderRelations != true" class="collect" @click="postTraderRelations({ traderId: userData['traderId'] })">
              收藏
            </div>
            <div class="w100"></div>
            <div class="text2" v-if="choice == 'recharge'">
              充值限額 : ¥ {{ userData["minLimit"] }} ~
              {{ userData["commission"] }}
            </div>
            <div class="text2" v-else-if="choice == 'withdraw'">
              提现限額 : ¥ {{ userData["withdrawMinLimit"] }} ~
              {{ userData["maxLimit"] }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-sb-c mb_4px">
        <div class="wrap__input">
          <div class="flex-sb-c">
            <div class="box__text">
              <div v-if="choice == 'recharge'">充值金額</div>
              <div v-else>帐户余额 : {{ usersData["commission"] }}</div>
            </div>

            <div class="box__img">
              <img v-if="userData['bankcardStatus'] == 1" src="@/assets/images/public/ic_my_money_aisle_bank.webp" alt="" />
              <img v-if="userData['alipayStatus'] == 1" src="@/assets/images/public/ic_my_money_aisle_alipay.webp" alt="" />
              <img v-if="userData['wechatpayStatus'] == 1" src="@/assets/images/public/ic_my_money_aisle_wechat.webp" alt="" />
            </div>
          </div>
          <div class="box__input">
            <input type="number" :placeholder="`请输入${inputName}金额`" v-model="popupValue" @keyup="replaceFloatingPoint()" @afterpaste="replaceFloatingPoint()" />
          </div>
          <div v-if="choice == 'withdraw'" class="box__input">
            <input type="password" minlength="6" maxlength="6" :placeholder="`请输入安全密码`" v-model="securityCode" />
          </div>
          <div class="box__tips" v-if="choice == 'recharge'">
            下单之后请在1分钟内支付
          </div>
        </div>
      </div>
      <div class="flex-sb-c mb_4px">
        <div class="wrap__btn flex-sb-c">
          <div class="btnn" @click="childClick()">取消</div>
          <div class="btnn" @click="orderSubmit()">下单</div>
        </div>
      </div>
      <div class="wrap__remark">
        <div class="mb_4px">卖家备注 :{{ userData["remark"] }}</div>
      </div>
    </div>
    <!-- 承擔費用彈窗 -->
    <div v-if="isCheckPopup == true">
      <PopupCheckPayMoney @isPopupCheckPayMoney="isPopupCheckPayMoney" :popupValue="popupValue" :userData="userData" :securityCode="securityCode" />
    </div>
    <div class="bindMobilePopup" v-if="isMobilePopup == true">
      <div class="zone">
        <div class="title">请绑定手机号</div>
        <div class="box__check">
          <div @click="isMobilePopup = false">取消</div>
          <div @click="$router.push('/bindMobile')">去设置</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PopupCheckPayMoney from "@/components/PopupCheckPayMoney.vue";
import { Message } from "element-ui";

export default {
  name: "PopupUserTrade",
  components: {
    PopupCheckPayMoney,
  },
  props: {
    isTraderRelations: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    usersData: {
      type: Object,
      default: function() {
        return {};
      },
    },
    inputName: {
      type: String,
      default: "",
    },
    choice: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      popupValue: "",
      isPopupUserTrade: false,
      isCheckPopup: false,
      isMobilePopup: false,
      securityCode: "",
    };
  },
  computed: {
    ...mapState("orders", ["buySubmitData", "withdrawSubmitData", "withdrawCheckoutData"]),
  },
  watch: {
    buySubmitData() {
      this.$router.push({
        path: "/orderInformation",
        query: {
          orderNo: this.buySubmitData.orderNo,
          orderId: this.buySubmitData.orderId,
        },
      });
    },
    withdrawCheckoutData() {
      this.isCheckPopup = true;
    },
  },
  methods: {
    ...mapActions("orders", ["postBuySubmit", "postWithdrawSubmit", "postWithdrawCheckout"]),
    getAvatar(avatar) {
      if (avatar) return avatar;
      return require("@/assets/images/public/ic_my_head.webp");
    },

    orderSubmit() {
      if (this.popupValue == "") {
        return Message({
          message: "請輸入金額",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      }
      if (this.choice == "recharge") {
        let data = {
          amount: this.popupValue,
          traderId: this.userData.traderId,
        };
        this.postBuySubmit(data).then((res) => {
          if (res.msg == "请先进行手机号绑定！") {
            this.isMobilePopup = true;
          }
        });
      } else if (this.choice == "withdraw") {
        if (this.securityCode == "") {
          return Message({
            message: "請輸入安全密碼",
            iconClass: "x",
            center: true,
            customClass: "error_message",
          });
        }
        let data = {
          amount: this.popupValue,
          securityCode: this.securityCode,
        };
        this.postWithdrawCheckout(data);
      }
    },
    childClick() {
      this.$emit("isPopupUserTrade", this.isPopupUserTrade);
    },
    replaceFloatingPoint() {
      this.popupValue = this.popupValue.toString().replace(/\D/g, "");
    },
    isPopupCheckPayMoney(isPopupCheckPayMoney) {
      this.isCheckPopup = isPopupCheckPayMoney;
    },
    postTraderRelations(traderId) {
      this.$emit("postTraderRelations", traderId);
    },
  },
};
</script>
<style lang="scss" scoped>
.bindMobilePopup {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;

  .zone {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    height: 120px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;

    .title {
      color: gray;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .box__check {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 16px;
      padding-bottom: 16px;

      div:nth-child(1) {
        width: 50%;
      }

      div:nth-child(2) {
        width: 50%;
        color: rgb(255, 115, 0);
      }
    }
  }
}

.PopupUserTrade {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .container__close {
    padding-top: 6rem;
  }

  .container__info {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 10px 10px 0 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    position: relative;
    // max-width: 414px;
    margin: 0 auto;
    @media (min-width: 480px) {
      max-width: unset;
    }
    .wrap__close {
      position: absolute;
      right: 0.6rem;
      top: 0.4rem;
      width: 1.5rem;

      img {
        width: 100%;
      }
    }

    .wrap__info {
      display: flex;
      align-items: center;

      .box__img {
        width: 3rem;
        margin-right: 0.6rem;
        font-size: 0;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .box__img2 {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.6rem;
        font-size: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .box__name {
        font-size: 1rem;
        font-weight: bold;
      }

      .text {
        color: var(--gray);
        font-size: 0.9rem;
      }

      .text2 {
        font-size: 0.9rem;
      }

      .btnn {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: var(--orange);
        color: white;
        font-size: 0.9rem;
      }

      .collect {
        margin-left: 0.4rem;
        font-size: 0.7rem;
        color: var(--orange);
        border-bottom: 1px solid rgb(255, 115, 0);
      }

      .w100 {
        margin-bottom: 0.4rem;
      }
    }

    .wrap__input {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .box__img {
        width: 1.6rem;
        margin-right: 0.6rem;
        font-size: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .box__input {
        width: 100%;
        margin-top: 0.5rem;

        input {
          width: 100%;
          outline-style: none;
          // border-bottom: 0px;
          // border-right: 0px;
          // border-top: 1px solid lightgray;
          // border-left: 1px solid lightgray;
          border: 1px solid lightgray;
          padding: 0.5rem;
          font-size: 1rem;
        }
      }

      .box__tips {
        margin-top: 0.5rem;
        text-align: left;
        font-size: 0.8rem;
        color: var(--gray);
      }

      .box__text {
        font-weight: bold;
      }
    }

    .wrap__btn {
      width: 100%;
      font-weight: bold;
      white-space: nowrap;

      .btnn:nth-child(1) {
        width: 20rem;
        padding: 0.7rem;
        border-radius: 20px;
        background-color: #d6d6d6;
        margin-right: 0.5rem;
      }

      .btnn:nth-child(2) {
        width: 20rem;
        padding: 0.7rem;
        border-radius: 20px;
        background-color: var(--orange);
        color: aliceblue;
      }
    }

    .wrap__remark {
      width: 100%;
      margin-top: 2rem;
      text-align: left;
      color: var(--gray);
      word-wrap: break-word;
    }
  }
}
</style>
