<template>
  <div class="PopupCheckPayMoney" v-show="feeAmount !== 0">
    <div class="container__main">
      <div class="title">重要提示</div>
      <div class="content">
        您的提现金额 {{ popupValue }}，其中
        {{ popupValue - withdrawCheckoutData["withdrawCommission"] }}
        为常驻用户充值，未经过商户流通。因本平台已向常驻用户支付商户奖励，为防止刷单套取平台奖励，您需要承担此笔费用共计
        {{ withdrawCheckoutData["feeAmount"] }} 元。
      </div>
      <div class="box__btn">
        <div @click="childClick()">取消</div>
        <div @click="sureTakeMyMoney()">確定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "PopupCheckPayMoney",
  props: {
    popupValue: {
      type: String,
      default: "0",
    },
    securityCode: {
      type: String,
      default: "",
    },
    userData: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      PopupCheckPayMoney: false,
    };
  },
  computed: {
    ...mapState("orders", ["buySubmitData", "withdrawSubmitData", "withdrawCheckoutData"]),
    feeAmount() {
      return this.withdrawCheckoutData["feeAmount"];
    },
  },
  watch: {
    withdrawCheckoutData: {
      handler: function(newVal) {
        if (newVal.feeAmount === 0) {
          this.sureTakeMyMoney();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("orders", ["postBuySubmit", "postWithdrawSubmit", "postWithdrawCheckout"]),
    childClick() {
      this.$emit("isPopupCheckPayMoney", this.isPopupCheckPayMoney);
    },
    sureTakeMyMoney() {
      this.data = {
        traderId: this.userData.traderId,
        amount: this.popupValue,
        securityCode:this.securityCode
      };
      this.postWithdrawSubmit(this.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.PopupCheckPayMoney {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .container__main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: aliceblue;
    width: 80%;
    height: fit-content;
    padding: 2rem 1rem;
    border-radius: 15px;
    // max-width: 414px;
    @media (min-width: 480px) {
      max-width: unset;
    }
    .title {
      font-weight: bold;
      color: var(--orange);
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }

    .content {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    .box__btn {
      display: flex;
      justify-content: space-around;

      div {
        width: 40%;
        padding: 0.8rem 0rem;
        color: aliceblue;
        border-radius: 20px;

        &:nth-child(1) {
          background-color: var(--light-black);
        }

        &:nth-child(2) {
          background-color: var(--orange);
        }
      }
    }
  }
}
</style>
